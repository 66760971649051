import React from "react"
import { graphql } from "gatsby"
import BlogLayout from "../../../components/Blog/BlogLayout"
import Header from "../../../components/Header"
import BlogContentContainer from "../../../components/Blog/BlogContentContainer"
import Template from "../../../components/Blog/Tag/Template"
import Footer from "../../../components/Footer"
import { IndexQuery } from "../../../components/Query/IndexPage"
import { connect } from "react-redux"

const TagsTemplate = ({ data, language, dispatch }) => {
  const dataQuery = IndexQuery()

  let dataLang = {}

  dataQuery.allContentfulIndex.nodes.forEach(node => {
    if (language === node.node_locale) {
      dataLang = node
    }
  })

  let dataTagLang = []

  data.allContentfulBlogArticle.edges.forEach(({ node }) => {
    if (language === node.node_locale) {
      dataTagLang.push(node)
    }
  })

  return (
    <BlogLayout>
      <Header
        indexPage={false}
        data={dataLang.header}
        dispatch={dispatch}
        language={language}
      />
      <BlogContentContainer>
        <Template
          data={dataTagLang}
          title={dataLang.blog.title}
          language={language}
        />
      </BlogContentContainer>
      <Footer data={dataLang.footer} />
    </BlogLayout>
  )
}

export default connect(state => state.languageReducer, null)(TagsTemplate)

export const query = graphql`
  query TagQuery($tag: String) {
    allContentfulBlogArticle(filter: { tagsInEnglish: { in: [$tag] } }) {
      edges {
        node {
          node_locale
          id
          tags
         tagsInEnglish
          summary {
            summary
          }
          image {
            file {
              url
            }
          }
          date
          titleAndLink {
            url
            name
          }
        }
      }
    }
  }
`

import React from "react"
import Article from "../../../contentfulModels/Blog/Article"

import "../BlogContent.scss"

function Template({ data, title, language }) {
  return (
    <div className="blog__container">
      <div className="blog__container_container">
        <div className="blog__title">
          <h4>{title}</h4>
        </div>
        <section className="blog__table">
          {data.map(
            ({
              id,
              date,
              image,
              summary,
              tags,
              tagsInEnglish,
              titleAndLink,
            }) => (
              <Article
                key={id}
                id={id}
                title={titleAndLink.name}
                url={titleAndLink.url}
                imageSrc={image.file.url}
                tagsInEnglish={tagsInEnglish}
                tags={tags}
                date={date}
                language={language}
                summary={summary ? summary.summary : null}
              />
            )
          )}
        </section>
      </div>
    </div>
  )
}

export default Template
